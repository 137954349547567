<template>
  <div>
    <page-header name="Processar Sorteio">
      <b-button
        variant="primary"
        @click="showLotteryNumbers"
      >
        <FeatherIcon icon="SearchIcon" class="mr-1"/>
        <span class="text-nowrap">Obter Números</span>
      </b-button>
    </page-header>
    <b-card>
      <b-card-title>Números</b-card-title>
      <b-card-sub-title>{{ labelLottery }}</b-card-sub-title>
      <b-card-body>
        <b-row>
          <b-col>
            <i2-form-input
              v-model="model.numbers[0]"
              rules="required"
              label="1º número"
              placeholder="1º numero"
              name="first_number"
            />
          </b-col>
          <b-col>
            <i2-form-input
              v-model="model.numbers[1]"
              rules="required"
              label="2º número"
              placeholder="2º numero"
              name="second_number"
            />
          </b-col>
          <b-col>
            <i2-form-input
              v-model="model.numbers[2]"
              rules="required"
              label="3º número"
              placeholder="3º numero"
              name="second_number"
            />
          </b-col>
          <b-col>
            <i2-form-input
              v-model="model.numbers[3]"
              rules="required"
              label="4º número"
              placeholder="4º numero"
              name="second_number"
            />
          </b-col>
          <b-col>
            <i2-form-input
              v-model="model.numbers[4]"
              rules="required"
              label="5º número"
              placeholder="5º numero"
              name="second_number"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5">
            <i2-form-input
              v-model="model.total_of_winners"
              rules="required"
              disabled
              label="Número de Vencedores"
              placeholder="Número de Vencedores"
              name="total_winners"
            />
          </b-col>
          <b-col sm="5">
            <i2-form-vue-select
              v-model="model.alternated"
              rules="required"
              :options="alternatesList"
              label="Suplentes"
              name="alternates"
            />
          </b-col>
          <b-col sm="2">
            <b-form-group
              label=""
            >
              <br>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mr-2"
                :disabled="status === 2 || isBlockHomologation"
                @click="onProcessNumbers"
              >

                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Processar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <b-card-title>Resumo</b-card-title>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3" style="height: 100px;">
          <div class="box-info" style="background-color: #42A2B9; ">
            <h1>{{ model.serie }}</h1>
            <b-card-sub-title>Série</b-card-sub-title>
            {{ model.text_serie }}
          </div>
        </b-col>
        <b-col sm="3">
          <div class="box-info" style="background-color: #157AF6; ">
            <h1>{{ model.number }}</h1>
            <b-card-sub-title>Número Extraído</b-card-sub-title>
            {{ model.extracted_numbers }}
          </div>
        </b-col>
        <b-col sm="3">
          <div class="box-info" style="background-color: #4CA746;">
            <h1>{{ model.full_number }}</h1>
            <b-card-sub-title>Número para Sorteio</b-card-sub-title>
            Série + Numero
          </div>
        </b-col>
        <b-col sm="3">
          <div class="box-info" style="background-color: #343A40;">
            <h1>Totais</h1>
            {{ model.number_of_winners }} Contemplados <br>
            {{ model.total_alternated || 0 }} Suplentes <br>
          </div>
        </b-col>

      </b-row>
      <b-row class="mt-1">
        <b-col sm="6">
          <div class="box-info" :style="{backgroundColor: drawStatus.color}">
            <b>Status do Sorteio</b>
            <h1>{{ drawStatus.status }}</h1>
            {{ drawStatus.user }}
          </div>
        </b-col>
        <b-col sm="6">
          <div class="box-info" :style="{backgroundColor: prizesStatusInfo.color} ">
            <h1>Premiação</h1>
            {{ prizesStatusInfo.status }}
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <b-card-title>Resultado</b-card-title>
        </b-col>
        <b-col class="text-right">
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Ações"
            right
            variant="outline-secondary"
          >
            <b-dropdown-item @click="onWinnerReport">
              <feather-icon
                icon="PrinterIcon"
                size="18"
              />&nbsp; Imprimir Relatório
            </b-dropdown-item>
            <b-dropdown-item @click="onDownloadWinners">
              <feather-icon
                icon="AwardIcon"
                size="18"
              />&nbsp; Exportar Vencedores
            </b-dropdown-item>
            <b-dropdown-item @click="onDownloadLog">
              <feather-icon
                icon="DownloadCloudIcon"
                size="18"
              />&nbsp;Download do Log de processamento
            </b-dropdown-item>
            <b-dropdown-item :disabled="status !== 0 && isBlockHomologation" @click="onReprocessPrize()">
              <feather-icon
                icon="AwardIcon"
                size="18"
              />&nbsp;Distribuir Premiação
            </b-dropdown-item>
            <b-dropdown-item v-if="status === 0" :disabled="isBlockHomologation" @click="onApproveDraw(true)">
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
              />&nbsp;Homologar Sorteio
            </b-dropdown-item>
            <b-dropdown-item v-if="status === 2" :disabled="isBlockHomologation" @click="onApproveDraw(false)">
              <feather-icon
                icon="DeleteIcon"
                size="18"
              />&nbsp;Cancelar Homologação
            </b-dropdown-item>
            <b-dropdown-item v-if="status === 0 && segments.length > 0 && currentSegmentIsOpen()"
                             @click="onMarkSegmentADone(true)"
            >
              <feather-icon
                icon="CheckIcon"
                size="18"
              />&nbsp;Marcar Segmento como concluído
            </b-dropdown-item>
            <b-dropdown-item v-if="status === 0 && segments.length > 0 && search.segment && !currentSegmentIsOpen()"
                             @click="onMarkSegmentADone(false)"
            >
              <feather-icon
                icon="DeleteIcon"
                size="18"
              />&nbsp;Abrir Segmento
            </b-dropdown-item>
            <b-dropdown-item v-if="canGenerateReceipt()"
                             @click="onGenerateReceipt()"
            >
              <feather-icon
                icon="FileTextIcon"
                size="18"
              />&nbsp;Gerar Recibos de Entrega
            </b-dropdown-item>
            <b-dropdown-item :disabled="isBlockHomologation" @click="showSegmentStatus">
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
              />&nbsp;Situação dos Segmentos
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        :tbody-tr-class="rowClass"
        @onChangePage="getWinners"
      >
        <template v-slot:search>
          <b-row class="p-0 pl-1">
            <b-col
              :md="segments.length > 0 ? 5: 10"
              class="p-0 pr-1"
            >
              <b-form-input
                v-model="search.search"
                class="filter-input"
                placeholder="Pesquisar por nome"
                name="nome"
              />
            </b-col>
            <b-col
              v-if="segments.length > 0"
              md="5"
              class="p-0 pr-1"
            >
              <i2-form-vue-select
                v-model="search.segment"
                class="filter-input"
                :options="segments"
                placeholder="Segmento"
                name="segment"
              >
              </i2-form-vue-select>

            </b-col>
            <b-col
              sm="2"
              md="2"
            >
              <b-button
                variant="secondary"
                type="button"
                @click="getWinners"
              >
                <FeatherIcon icon="SearchIcon"/>
                <span
                  class="text-nowrap"
                />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template
          #cell(number)="data"
        >
          <div>

            <span class="link-info" @click="showNumberDetail(data.item.number)">{{ data.item.number }}
            </span>
          </div>
        </template>
        <template
          #cell(document)="data"
        >
          <div>
            <div>
              <span class="link-info" @click="setCurrentParticipant(data.item.person)">{{ data.item.document }}
              </span>
            </div>
          </div>
        </template>
        <template
          #cell(type_winner)="data"
        >
          <div v-b-popover.hover="data.item.reason_disqualify">
            <b-badge
              :variant="getVariantWinner(data.item)"
            >
              {{ getLabelWinner(data.item) }}
            </b-badge>
            <winner-prize-status v-if="data.item.type === 1 && data.item.status === 'active'"
                                 :status="data.item.prize_status"
            />
          </div>
        </template>
        <template
          #cell(action)="data"
          style="width: 20%"
        >
          <div class="px-1 text-center">
            <b-button-group>
              <i2-icon-button
                v-if="data.item.status === 'active'"
                v-b-popover.hover="'O participante será desclassificado do sorteio.'"
                icon="SlashIcon"
                variant="outline-danger"
                :disabled="status === 2"
                @click="onOpenDisqualify(data.item)"
              />

              <i2-icon-button
                v-if="data.item.status === 'disqualified'"
                v-b-popover.hover="'O participante será reclassificado no sorteio. Ele manterá a posição designada.'"
                icon="RefreshCwIcon"
                variant="outline-success"
                :disabled="status === 2"
                @click="onOpenRequalify(data.item)"
              />

              <i2-icon-button
                v-if="currentPrizeDraw.promotion.settings.is_instagram_promotion"
                v-b-popover.hover="'Ver todos os comentários do participante.'"
                title="Comentários do Participante"
                icon="SearchIcon"
                variant="outline-success"
                @click="showUserComments(data.item)"
              />
              <i2-icon-button
                v-if="data.item.type === 1 && data.item.status === 'active' && data.item.prize_status !== 'not_found'"
                v-b-popover.hover="'Documentação do Vencedor'"
                icon="FileIcon"
                variant="outline-success"
                @click="showWinnerDocumentation(data.item)"
              />
              <i2-icon-button
                v-if="data.item.type === 1 && data.item.status === 'active' && data.item.prize_status !== 'not_found'"
                v-b-popover.hover="'Marcar vencedor como não encontrado'"
                fa-icon="fas fa-user-slash"
                variant="outline-danger"
                @click="onWinnerNotFound(true, data.item)"
              />

              <i2-icon-button
                v-if="data.item.type === 1 && data.item.status === 'active' && data.item.prize_status === 'not_found'"
                v-b-popover.hover="'Remover vencedor como não encontrado'"
                fa-icon="fas fa-user-slash"
                variant="outline-warning"
                @click="onWinnerNotFound(false,data.item)"
              />

            </b-button-group>

          </div>
        </template>
      </Grid>
    </b-card>
    <lottery-numbers-modal :is-active.sync="isLotteryModalActive"
                           @onCancel="onCancelLotteryModal"
                           @onSave="saveLotteryNumbers"
    />
    <b-modal
      id="modal-disqualify"
      ref="modalDisqualify"
      cancel-variant="outline-secondary"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      centered
      ok-title="Confirmar"
      cancel-title="Fechar"
      size="lg"
      title="Desclassificar Participante"
      @ok="onDisqualify"
      @close="onCancelDisqualify"
      @cancel="onCancelDisqualify"
    >
      <h4>Confirma a desclassificação do participante? Informe o motivo abaixo:</h4>
      <div class="form-group">
        <textarea id="motivo_desclassificacao" v-model="disqualifyReason"
                  class="form-control"
                  placeholder="Motivo da desclassificação"
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-requalify"
      ref="modalRequalify"
      cancel-variant="outline-secondary"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      centered
      ok-title="Confirmar"
      cancel-title="Fechar"
      size="lg"
      title="Reclassificar Participante"
      @ok="onRequalify"
      @close="onCancelRequalify"
      @cancel="onCancelRequalify"
    >

      <h4>Confirma a reclassificação do participante? Informe o motivo abaixo:</h4>
      <div class="form-group">
        <textarea id="motivo_reclassificacao" v-model="disqualifyReason"
                  class="form-control"
                  placeholder="Motivo da reclassifição"
        />
      </div>
    </b-modal>

    <prize-draw-comments-by-person
      :is-open.sync="isShowInstagramComments"
      :information="currentPersonInstagramComments"
      @close="closeInstagramComments"
    />
    <number-detail-modal :show.sync="showModalNumber" :full-number="currentNumber" @onClose="onCloseNumberModal"/>

    <participant-detail-modal
      title="Detalhes do Participante"
      :show.sync="isShowParticipant"
      :participant-uuid="currentParticipant"
      @onClose="onCloseParticipantModal"
    />

    <prize-draw-winner-document-modal
      v-model="currentWinner"
      @close="currentWinner = null"
      @onSave="onAddDocumentWinner"
    />

    <b-sidebar
      v-model="showSegments"
      right
      title="Lista de Segmentos"
      backdrop
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      bg-variant="white"
      shadow
      sidebar-class="sidebar-lg"
      header-class="content-sidebar-header"
      footer-class="content-sidebar-footer"
      @hidden="(val) => showSegments = false"
    >
      <div class="p-2">
        <b-button class="rounded rounded-2 mr-1"
                  :variant="segmentFilters.includes('open') ? 'warning' : 'outline-warning'"
                  @click="filterSegment('open')"
        >
          Abertos
        </b-button>
        <b-button class="rounded"
                  :variant="segmentFilters.includes('done') ? 'success' : 'outline-success'"
                  @click="filterSegment('done')"
        >
          Fechados
        </b-button>
      </div>
      <b-list-group>
        <b-list-group-item v-for="(segment, i) of filteredSegments"
                           :key="i" :class="{
                             'bg-light-success':segment.item.status === 'done',
                             'bg-light-warning':segment.item.status === 'open'
                           }"
        >
          <div class="d-flex w-100 justify-content-between ">
            <h5 class="mb-1">
              {{ segment.text }}
            </h5>
          </div>
          <div v-if="segment.item.status === 'done'" class="d-flex w-100 justify-content-between">
            <small>Confirmado por: <b>{{ segment.item.confirmed_by.name }}</b></small>
            <small>Em {{ segment.item.confirmed_at | moment('DD/MM/YYY HH:mm') }}</small>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-sidebar>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButtonGroup, BListGroup, BListGroupItem, VBPopover,
} from 'bootstrap-vue'
import LotteryNumbersModal from '@/components/app/lottery/LotteryNumbersModal.vue'
import PrizeDrawCommentsByPerson from '@/views/prize-draw/PrizeDrawCommentsByPerson'
import NumberDetailModal from '@/components/app/numbers/NumberDetailModal.vue'
import ParticipantDetailModal from '@/views/participants/ParticipantDetailModal'
import { getUserData } from '@/auth/utils'
import PrizeDrawWinnerDocumentModal from '@/components/app/prize-draw/PrizeDrawWinnerDocumentModal.vue'
import WinnerPrizeStatus from '@/components/app/prize-draw/WinnerPrizeStatus.vue'
import moment from 'moment-timezone'

export default {
  name: 'ProcessPrizeDraw',
  components: {
    BListGroup,
    BListGroupItem,
    WinnerPrizeStatus,
    PrizeDrawWinnerDocumentModal,
    BButtonGroup,
    NumberDetailModal,
    PrizeDrawCommentsByPerson,
    LotteryNumbersModal,
    ParticipantDetailModal,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      showSegments: false,
      user: getUserData(),
      currentWinner: null,
      search: {
        search: '',
        type: '',
        segment: null,
      },
      fields: [
        {
          key: 'order',
          label: 'Ordem',
          class: 'w-10',
        },
        {
          key: 'number',
          label: 'Número',
          class: 'w-10',
        },
        {
          key: 'document',
          label: 'Documento',
          class: 'w-10',
        },
        {
          key: 'name',
          label: 'nome',
          class: 'w-30',
        },
        {
          key: 'segment.name',
          label: 'segmento',
          class: 'w-20',
        },
        {
          key: 'prize',
          label: 'Prêmio',
          class: 'w-10',
        },
        {
          key: 'type_winner',
          label: 'Situação',
          class: 'w-10',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentUser: getUserData(),
      isLotteryModalActive: false,
      showModalNumber: false,
      items: [],
      currentPromotion: null,
      alternatesList: [],
      labelLottery: '',
      currentPrizeDraw: null,
      currentParticipant: null,
      disqualifyReason: '',
      currentNumber: '',
      status: 0,
      isShowInstagramComments: false,
      currentPersonInstagramComments: {},
      segments: [],
      filteredSegments: [],
      prizeStatus: {
        status: 'not_started',
        delivered_percentage: 0,
      },
      currentSegment: null,
      model: {
        numbers: [],
        numbers_list: '',
        number_of_winners: 1,
        total_of_winners: 1,
        alternates: 0,
        extract_serie: '',
        extracted_number: '',
        text_serie: '',
        full_number: '',
        homologated_at: '',
        processed_at: '',
        homologated_by: {
          name: '',
        },
        processed_by: {
          name: '',
        },
      },
      isShowParticipant: false,
      isBlockHomologation: true,
      segmentFilters: ['open', 'done'],
    }
  },

  computed: {
    drawStatus() {
      let formattedDate = ''
      let data = {
        status: 'Não Processado',
        color: '#D20005',
        user: '',
      }

      if (this.status === 0 && this.items.length > 0) {
        formattedDate = moment(this.model.processed_at)
          .format('DD/MM/YYYY HH:mm')
        data.status = 'Processado'
        data.color = 'var(--warning)'
        data.user = `Em ${formattedDate} por ${this.model.processed_by?.name}`
      }
      if (this.status === 2) {
        formattedDate = moment(this.model.homologated_at)
          .format('DD/MM/YYYY HH:mm')
        data.status = 'Homologado'
        data.color = 'var(--success)'
        data.user = `Em ${formattedDate} por ${this.model.homologated_by?.name}`
      }
      return data
    },
    prizesStatusInfo() {
      const status = {
        color: '#D20005',
        status: 'Entrega não iniciada',
      }
      if (this.prizeStatus.status === 'started') {
        const percentage = new Intl.NumberFormat('pt-BR').format(this.prizeStatus.delivered_percentage)
        status.color = '#157AF6'
        status.status = `Entrega Iniciada (${percentage}%)`
      }
      if (this.prizeStatus.status === 'done') {
        status.color = '#29C76F'
        status.status = 'Entrega Finalizada'
      }
      return status
    },
  },
  async created() {
    const alternatedOptions = [0, 10, 20, 40, 80, 100, 150, 200]
    this.alternatesList = alternatedOptions.map(item => ({
      text: `+ ${item}`,
      value: item,
    }))
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }

    this.currentPrizeDraw = prizeDraw
    await this.getPrizeDrawSegments()
    await this.getPrizeDrawProcessInfo()
    if (this.segments.length === 0) {
      this.fields.splice(4, 1)
    }
    if (this.segments.length > 0) {
      this.search.segment = this.segments[0]
    }
    this.model.alternated = this.alternatesList[0]
    await this.getManagers()
    await this.getWinners()
    this.$forceUpdate()
  },
  methods: {
    showSegmentStatus() {
      this.showSegments = true
    },
    filterSegment(type) {
      if (this.segmentFilters.includes(type)) {
        this.segmentFilters = this.segmentFilters.filter(item => type !== item)
        this.filteredSegments = this.segments.filter(segment => this.segmentFilters.includes(segment.item.status))

        return
      }
      this.segmentFilters.push(type)

      this.filteredSegments = this.segments.filter(segment => this.segmentFilters.includes(segment.item.status))
    },
    async onProcessNumbers() {
      const data = { numbers: this.model.numbers }
      const response = await this.$http.post(`/prize-draws/${this.currentPrizeDraw.uuid}/process-number`, data)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      const extractNumbers = { ...response }
      const originalData = { ...this.model }
      extractNumbers.extracted_numbers = response.numbers.map((number, i) => `${(i + 1)}º: ${number}`)
        .join(', ')
      this.model = extractNumbers
      this.model.alternated = originalData.alternated
      this.model.number_of_winners = originalData.number_of_winners
      this.onProcessDraw()
    },

    async onProcessDraw() {
      const data = {
        serie: this.model.serie,
        number: this.model.number,
        contemplated: this.model.number_of_winners,
        alternate: this.model.alternated.value || 0,
      }
      const response = await this.$http.post(`/prize-draws/${this.currentPrizeDraw.uuid}/process-draw`, data)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Sorteio processado com sucesso!')

      await this.getPrizeDrawProcessInfo()
      await this.getWinners()
    },

    async getPrizeDrawProcessInfo() {
      const response = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/draw-info`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      const extractNumbers = { ...response }
      extractNumbers.extracted_numbers = response.numbers.map((number, i) => `${(i + 1)}º: ${number}`)
        .join(', ')
      this.model = extractNumbers
      this.status = response.status
      this.groupWinners(extractNumbers.winners)

      this.getPrizeDrawStatus()
    },
    showLotteryNumbers() {
      this.isLotteryModalActive = true
    },
    saveLotteryNumbers(data) {
      this.model.numbers = data.numbers
      this.labelLottery = data.label
      this.isLotteryModalActive = false
    },
    onCancelLotteryModal() {
      this.isLotteryModalActive = false
    },

    async getWinners(page) {
      const response = await this.$search.get(`/prize-draws/${this.currentPrizeDraw.uuid}/winners`, page, this.search)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      this.groupWinners(response)
    },

    async onDisqualify(event) {
      if (!this.currentParticipant) {
        return
      }
      if (this.disqualifyReason.trim() === '') {
        this.$notify.error('Informe um motivo para a desclassificação do Participante.')
        event.preventDefault()
        return
      }
      if (this.segmentNotSelected()) {
        this.$notify.error('Selecione um segmento antes de continuar.')
        return
      }
      if (!this.currentSegmentIsOpen()) {
        this.$notify.error('O segmento selecionado está fechado. Manutenções estão bloqueadas.')
        return
      }
      const data = {
        reason: this.disqualifyReason,
      }
      const participantUUid = this.currentParticipant.uuid
      let segmentRoute = ''
      if (this.search.segment && this.search.segment.value) {
        segmentRoute = `?segment=${this.search.segment.value}`
      }
      const response = await this.$http.post(
        `prize-draws/${this.currentPrizeDraw.uuid}/disqualify/${participantUUid}${segmentRoute}`,
        data,
      )
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success(`Participante ${this.currentParticipant.name} foi desqualificado.`)
      this.getWinners()
      this.disqualifyReason = ''
      this.currentParticipant = null
    },

    onOpenDisqualify(item) {
      this.$refs.modalDisqualify.show()
      this.currentParticipant = item
    },
    onOpenRequalify(item) {
      this.$refs.modalRequalify.show()
      this.currentParticipant = item
    },
    onCancelDisqualify() {
      this.disqualifyReason = ''
      this.$refs.modalDisqualify.hide()
      this.currentParticipant = null
    },

    segmentNotSelected() {
      return this.segments.length > 0 && !this.search.segment
    },

    async onRequalify(event) {
      if (this.disqualifyReason.trim() === '') {
        this.$notify.error('Informe um motivo para a reclassificação do Participante.')
        event.preventDefault()
        return
      }
      if (this.segmentNotSelected()) {
        this.$notify.error('Selecione um segmento antes de continuar.')
        return
      }
      if (!this.currentSegmentIsOpen()) {
        this.$notify.error('O segmento selecionado está fechado. Manutenções estão bloueadas.')
        return
      }
      if (!this.currentParticipant) {
        return
      }
      const data = {
        reason: this.disqualifyReason,
      }
      const participantUUid = this.currentParticipant.uuid
      let segmentRoute = ''
      if (this.search.segment && this.search.segment.value) {
        segmentRoute = `?segment=${this.search.segment.value}`
      }
      const response = await this.$http.post(
        `prize-draws/${this.currentPrizeDraw.uuid}/reclassified/${participantUUid}${segmentRoute}`,
        data,
      )
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success(`Participante ${this.currentParticipant.name} foi requalificado.`)
      this.getWinners()
      this.disqualifyReason = ''
      this.currentParticipant = null
    },
    onCancelRequalify() {
      this.disqualifyReason = ''
      this.$refs.modalDisqualify.hide()
      this.currentParticipant = null
    },

    groupWinners(winners) {
      let items = []
      let position = 1
      const desqualifyeds = []
      for (const winner of winners) {
        if (winner.status !== 'active') {
          const winnerDisqualified = { ...winner }
          winnerDisqualified.order = ' - '
          winnerDisqualified._rowVariant = 'warning'
          desqualifyeds.push(winnerDisqualified)
          continue
        }
        winner.order = position
        position++
        items.push(winner)
      }
      items = items.concat(desqualifyeds)
      this.items = items
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return null
      }
      if (item.status === 'disqualified') {
        return 'teste-grid'
      }
      return null
    },
    async onWinnerReport() {
      let segment = ''
      if (this.search.segment?.value) {
        segment = `?segment=${this.search.segment.value}`
      }
      const response = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/winner-report${segment}`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('O relatório está na fila de processamento e você deverá recebê-lo em seu e-mail. Aguarde!')
    },

    async onDownloadWinners() {
      const response = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/winners?format=xls`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      window.open(response.url, '_blank')
    },
    async onDownloadLog() {
      const response = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/process-log`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      window.open(response.url, '_blank')
    },

    async onApproveDraw(isAprove) {
      const route = `/prize-draws/${this.currentPrizeDraw.uuid}/status`
      let confirmText = 'Por favor, confirme a homologação do sorteio.'
      let successMessage = 'Sorteio está homologado, e a partir de agora já está público.'
      const data = {
        status: isAprove ? 'confirmed' : 'waiting',
      }
      if (!isAprove) {
        confirmText = 'Por favor, confirme o cancelamento da homologação do sorteio.'
        successMessage = 'Sorteio está aguardo homologação!.'
      }

      this.$bvModal.msgBoxConfirm(confirmText, {
        title: 'Confirmar?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.patch(route, data)
            if (response.error) {
              this.$notify.error(response.error_message)
              return false
            }
            this.$notify.success(successMessage)
            this.status = response.status
            return true
          }
          return true
        })
    },

    async onMarkSegmentADone(isAprove) {
      const route = `/prize-draws/${this.currentPrizeDraw.uuid}/segments/${this.search.segment.value}/status`
      let confirmText = 'Por favor, confirme que todos os dados do seu segmento estão corretos. Ele será marcado como concluído'
      let successMessage = 'Segmento foi marcado como concluído.'
      const data = {
        status: isAprove ? 'done' : 'open',
      }
      if (!isAprove) {
        confirmText = 'Por favor, confirme o abertura do segmento.'
        successMessage = 'Segmento está aberto para edições novamente.'
      }

      this.$bvModal.msgBoxConfirm(confirmText, {
        title: 'Confirmar?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.patch(route, data)
            if (response.error) {
              this.$notify.error(response.error_message)
              return false
            }
            this.$notify.success(successMessage)
            await this.getPrizeDrawSegments()
            this.search.segment = this.segments.find(item => item.value === this.search.segment.value)
            return true
          }
          return true
        })
    },

    onReprocessPrize() {
      this.$bvModal.msgBoxConfirm('Confirmar a (re)distribuição dos prêmios?', {
        title: 'Confirmar?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.put(`/prize-draws/${this.currentPrizeDraw.uuid}/process-prizes`)
            if (response.error) {
              this.$notify.error(response.error_message)
              return false
            }
            this.$notify.success('Prêmios re(distribuídos) com sucesso!')
            this.getWinners()
            return true
          }
          return true
        })
    },
    currentSegmentIsOpen() {
      if (this.segments.length === 0) {
        return true
      }
      return this.search.segment?.item?.status === 'open'
    },
    showUserComments(info) {
      this.isShowInstagramComments = true
      this.currentPersonInstagramComments = info
    },
    closeInstagramComments() {
      this.isShowInstagramComments = false
      this.currentPersonInstagramComments = {}
    },
    showNumberDetail(number) {
      this.currentNumber = number
      this.showModalNumber = true
    },
    onCloseNumberModal() {
      this.currentNumber = ''
      this.showModalNumber = false
    },
    setCurrentParticipant(participant) {
      this.currentParticipant = participant.uuid
      this.isShowParticipant = true
    },

    onCloseParticipantModal() {
      this.currentParticipant = ''
      this.isShowParticipant = false
    },

    getVariantWinner(winner) {
      if (winner.current_order !== null) {
        if (winner.type === 1) {
          return 'success'
        }
        if (winner.type === 2) {
          return 'warning'
        }
      }
      if (winner.status === 'disqualified') {
        return 'danger'
      }
      if (winner.type === 1) {
        return 'success'
      }
      if (winner.type === 2) {
        return 'warning'
      }
      return ''
    },
    getLabelWinner(winner) {
      if (winner.current_order !== null) {
        if (winner.type === 1) {
          return 'Vencedor'
        }
        if (winner.type === 2) {
          return 'Suplente'
        }
      }
      if (winner.status === 'disqualified') {
        return 'desclassificado'
      }

      if (winner.order <= this.currentPrizeDraw.total_winners) {
        return 'Vencedor'
      }
      if (winner.order > this.currentPrizeDraw.total_winners) {
        return 'Suplente'
      }
      return ''
    },
    async getPrizeDrawSegments() {
      const response = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/segments?includes=confirmationUser`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }

      this.segments = response.map(item => {
        let status = ''
        if (item.status === 'done') {
          status = '✅'
        }
        return {
          value: item.id,
          text: `${status} ${item.name} - (${item.winners} vencedores)`,
          item,
        }
      })
      this.filteredSegments = this.segments
    },

    canGenerateReceipt() {
      let roles = ['admin', 'super_admin']
      return roles.some(role => role === this.user.role) && this.status === 2
    },

    async onGenerateReceipt() {
      let confirmation = await this.$bvModal.msgBoxConfirm('Confirma a geração dos recibos?', {
        title: 'Confirmar?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })

      if (!confirmation) {
        return
      }

      const data = {
        segment: null,
      }
      if (this.search.segment && this.search.segment.value) {
        data.segment = this.search.segment.value
      }

      let response = await this.$http.post(`/prize-draws/${this.currentPrizeDraw.uuid}/generate-receipts`, data)
      if (response.error) {
        this.$notify.error('Não foi possível gerar os recibos!')
        return
      }
      this.$notify.success('Solicitação de geração dos recibos realizada. Após o processo, os mesmos estarão disponíveis no google drive.')
    },

    showWinnerDocumentation(winner) {
      this.currentWinner = winner
    },
    onAddDocumentWinner() {
      this.getPrizeDrawStatus()
    },

    async getPrizeDrawStatus() {
      this.prizeStatus = await this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/prize-status`)
    },
    async onWinnerNotFound(setNotFound, winner) {
      let text = `Confirmar a marcação do Vencedor ${winner.name} como não encontrado?`
      let message = 'Vencedor marcado como não encontrado com sucesso!'
      if (!setNotFound) {
        text = `Confirmar a remoção do Vencedor ${winner.name} como não encontrado?`
        message = 'Removido a marcação de "não encontrado" com sucesso!'
      }
      let confirmation = await this.$bvModal.msgBoxConfirm(text, {
        title: 'Confirmar?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })

      if (!confirmation) {
        return
      }

      const data = {
        status: setNotFound ? 'not_found' : 'incomplete',
      }
      let response = await this.$http.put(`/winners/${winner.uuid}/status`, data)
      if (response.error) {
        this.$notify.error('Não foi possível ajustar o status do vencedor')
        return
      }
      this.$notify.success(message)
      await this.getWinners()
      await this.getPrizeDrawStatus()
    },
    async getManagers() {
      const response = await this.$http.get(`/promotions/${this.currentPrizeDraw.promotion.uuid}?include=managers`)
      const userManager = response.managers.find(manager => manager.uuid === this.currentUser.uuid)
      this.isBlockHomologation = !userManager && (this.currentUser.role !== 'super_admin' && this.currentUser.role !== 'admin')
    },

    canDoAction() {
      return this.isBlockHomologation
    },
  },

}
</script>

<style>
.box-info {
  height: 100%;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  color: white !important;
}

.box-info h1 {
  color: white !important;
}

.link-info {
  text-decoration: underline;
  cursor: pointer;
}

</style>
