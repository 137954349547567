<template>
  <b-modal
    id="numbers"
    ref="numbers"
    cancel-variant="outline-secondary"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered
    ok-title="Pesquisar"
    cancel-title="Fechar"
    size="lg"
    title="Pequisar Números na Loteria"
    @ok="onSaveNumbers"
    @close="onCancel"
    @cancel="onCancel"
  >
    <b-card>
      <h4>Consultar Números</h4>
      <b-card-body style="height: 300px">
        <b-row>
          <b-col>
            <i2-form-input-date
              v-model="model.date"
              rules="required"
              label="Data do Sorteio"
              placeholder="informe a data do Sorteio"
              name="total_winners"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  name: 'LotteryNumbersModal',
  directives: {
    Ripple,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      model: {
        date: '',
      },
      lastCode: null,
      currentPrizeDraw: null,
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.$refs.numbers.show()
      } else {
        this.$refs.numbers.hide()
      }
    },
  },
  async mounted() {
    if (this.isActive) {
      this.$refs.numbers.show()
    }
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      await this.$router.push('change-promotion.index')
      return
    }
    this.currentPrizeDraw = prizeDraw
  },
  methods: {
    async onSaveNumbers(event) {
      event.preventDefault()
      if (this.model.date === '') {
        return
      }
      const date = moment(this.model.date, 'DD/MM/YYYY')
        .format('YYYY-MM-DD')
      const route = `prize-draws/${this.currentPrizeDraw.uuid}/federal-lottery-numbers/${date}`
      const response = await this.$http.post(route)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$emit('onSave', response)
    },
    onCancel() {
      this.$emit('onCancel', true)
    },

    async getNumbersFromLottery(code, event) {
      let startUrl = 'https://servicebus2.caixa.gov.br/portaldeloterias/api/federal'
      if (code !== null) {
        startUrl += `/${code}`
      }

      const data = await fetch(startUrl)
      if (data.status === 500) {
        this.$notify.error('Sorteio não realizado para essa data.')
        return
      }
      let number = await data.json()

      if (number.dataApuracao !== this.model.date) {
        this.lastCode = number.numero - 1
        await this.getNumbersFromLottery(this.lastCode, event)
        return
      }
      const route = `prize-draws/${this.currentPrizeDraw.uuid}/federal-lottery-numbers/${number.numero}/save`
      const response = await this.$http.post(route, number)
      await this.onSaveNumbers(event)
    },

  },
}
</script>

<style scoped>

</style>
